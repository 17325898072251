var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-tbody',[_vm._l((_vm.teaching_profiles),function(teaching_profile,index){return [_c('b-tr',{key:'profile_header_' + teaching_profile.id},[_c('b-th',{attrs:{"colspan":"10","rowspan":"1"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-center",staticStyle:{"margin-left":"auto","margin-right":"auto","margin-top":"auto","margin-bottom":"auto"}},[_vm._v(" PERFIL DOCENTE "+_vm._s(index + 1)+" "),(
                    index == 0 &&
                    _vm.allows_crud &&
                    _vm.oldMatter &&
                    !_vm.oldMatter.is_closed
                  )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    `Agregar un nuevo Perfil Docente`
                  ),expression:"\n                    `Agregar un nuevo Perfil Docente`\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1",on:{"click":function($event){return _vm.createTeachingProfileBase()}}}):_vm._e(),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                    `Eliminar el Perfil Docente`
                  ),expression:"\n                    `Eliminar el Perfil Docente`\n                  ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-1",on:{"click":function($event){return _vm.deleteTeachingProfile(teaching_profile)}}}):_vm._e(),_c('div',{staticClass:"teacher-input"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2 secondary-color rounded",staticStyle:{"color":"white !important","padding":"0.1em"}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.total_pedagogical_hours", true, "Total de Horas Pedagógicas" ))+" "),(
                          !_vm.allows_crud || (_vm.oldMatter && _vm.oldMatter.is_closed)
                        )?_c('span',[_vm._v(": "+_vm._s(teaching_profile.total_hours ? teaching_profile.total_hours : "N/A")+" ")]):_c('b-form-input',{staticClass:"inputNumber",attrs:{"id":`input-teaching-profile-${teaching_profile.id}`,"type":"number","min":"0","state":_vm.totalPedagogical -
                            _vm.maxHoursTeachingProfile(teaching_profile.id) >=
                          teaching_profile.total_hours},on:{"input":function($event){return _vm.patchHourTeachingProfile(teaching_profile)}},model:{value:(teaching_profile.total_hours),callback:function ($$v) {_vm.$set(teaching_profile, "total_hours", _vm._n($$v))},expression:"teaching_profile.total_hours"}}),(
                          _vm.allows_crud &&
                          _vm.totalPedagogical -
                            _vm.maxHoursTeachingProfile(teaching_profile.id) <
                            teaching_profile.total_hours
                        )?_c('b-popover',{attrs:{"variant":"warning","placement":"bottom","target":`input-teaching-profile-${teaching_profile.id}`,"show":""}},[[_vm._v(" La suma de las horas de los docentes superó el máximo: "+_vm._s(_vm.totalPedagogical)+" ")]],2):_vm._e()],1),_c('div',{staticClass:"mr-2 secondary-color rounded",staticStyle:{"color":"white !important","padding":"0.1em"}},[_vm._v(" Años de experiencia laboral en el área "),(
                          !_vm.allows_crud || (_vm.oldMatter && _vm.oldMatter.is_closed)
                        )?_c('span',[_vm._v(": "+_vm._s(teaching_profile.experience_years ? teaching_profile.experience_years : "N/A")+" ")]):_c('b-form-input',{staticClass:"inputNumber",attrs:{"type":"number","min":"0"},on:{"input":(value) => {
                            if (
                              value >= 0 &&
                              teaching_profile.total_hours >= 0
                            )
                              _vm.patchTeachingProfile(teaching_profile);
                            else if (value < 0)
                              teaching_profile.experience_years = 0;
                          }},model:{value:(teaching_profile.experience_years),callback:function ($$v) {_vm.$set(teaching_profile, "experience_years", _vm._n($$v))},expression:"teaching_profile.experience_years"}})],1)])])],1)])])],1),_c('b-tr',{key:'professional_title_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.titulo_profesional", false, "Título Profesional" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.professional_title)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('button-edit',{attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                      `edit-professional_title-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                    )}}}),_c('b-modal',{attrs:{"id":`edit-professional_title-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                    'manual.titulo_profesional',
                    false,
                    'Título Profesional'
                  )}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.professional_title,"permit_blank":true},on:{"save":_vm.updateProfessionalTitle,"close":function($event){return _vm.$bvModal.hide(
                        `edit-professional_title-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                      )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'academic_degree_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.grado_academico", false, "Grado Académico" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.academic_degree)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('button-edit',{attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                      `edit-academic_degree-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                    )}}}),_c('b-modal',{attrs:{"id":`edit-academic_degree-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                    'manual.grado_academico',
                    false,
                    'Grado Académico'
                  )}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.academic_degree,"permit_blank":true},on:{"save":_vm.updateAcademicDegree,"close":function($event){return _vm.$bvModal.hide(
                        `edit-academic_degree-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                      )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'specialization_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.especializacion", false, "Especialización" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.specialization)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('button-edit',{attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                      `edit-specialization-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                    )}}}),_c('b-modal',{attrs:{"id":`edit-specialization-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                    'manual.especializacion',
                    false,
                    'Especialización'
                  )}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.specialization,"permit_blank":true},on:{"save":_vm.updateSpecialization,"close":function($event){return _vm.$bvModal.hide(
                        `edit-specialization-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                      )}}})],1)]:_vm._e()],2)])],1),_c('b-tr',{key:'required_skills_' + teaching_profile.id},[_c('b-td',{staticStyle:{"width":"25%"},attrs:{"colspan":"1"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.habilidades_requeridas", true, "Habilidades Requeridas" ))+":")])]),_c('b-td',{attrs:{"colspan":"9"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"wrapper2 rich-text-content w-100",domProps:{"innerHTML":_vm._s(teaching_profile.required_skills)}}),(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?[_c('button-edit',{attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                      `edit-required_skills-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                    )}}}),_c('b-modal',{attrs:{"id":`edit-required_skills-modal-${teaching_profile.id}-${_vm.matter_form_view}`,"title":`Editar ${_vm.$getVisibleNames(
                    'manual.habilidades_requeridas',
                    true,
                    'Habilidades Requeridas'
                  )}`,"size":"lg","hide-footer":""}},[_c('NewRichTextEditor',{attrs:{"Object":teaching_profile,"Text":teaching_profile.required_skills,"permit_blank":true},on:{"save":_vm.updateRequiredSkills,"close":function($event){return _vm.$bvModal.hide(
                        `edit-required_skills-modal-${teaching_profile.id}-${_vm.matter_form_view}`
                      )}}})],1)]:_vm._e()],2)])],1)]})],2)],1),(_vm.teaching_profiles.length == 0 && _vm.allows_crud && _vm.fetch_finish)?_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto mr-auto mb-2",attrs:{"variant":"secondary","size":"sm"},on:{"click":_vm.createTeachingProfileBase}},[_vm._v(" + Agregar Perfil Docente ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }